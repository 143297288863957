// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	getGroupedRoles() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('/User/GetGroupedRequestRoleLinks')
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	updateRequestRoleLink(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`/User/UpdateRequestRoleLink?requestId=${params.requestId}&roleId=${params.roleId}&isActive=${params.isActive}&actioType=${params.actioType}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};

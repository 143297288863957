<template>
	<div class="flex flex-col sm:w-auto px-4 py-6 sm:px-6 sm:py-12">
		<TableRoles />
	</div>
</template>

<script>
import TableRoles from './TableRoles.vue';

export default {
	name: 'Main',
	components: { TableRoles }
};
</script>
